import { hydrate } from "@/utils/form";
import {refine} from "@/utils/classes";

export default class PlaygroundOptions {
    '@id' = null;
    label = null;
    quantifiable = null;
    minQuantity = null;
    maxQuantity = null;
    qty = 1;
    price = null;
    selected = false;

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
            if(options.deserialize) {
                this.deserialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
        refine(this);
    }

    deserialize (object) {
        hydrate(this, object);
        refine(this);
    }

}
