<template>
  <div>
    <div v-if="participantsSelection">
      <b-row :class="innerWidth < 991 ? 'mt-4 mb-2' : 'mb-1'">
        <b-col class="field-name-class">
          {{ $t('components.custom.planning.booking-details-modal.general-information.add-participant') }}
        </b-col>
      </b-row>
      <b-row :class="innerWidth < 991 ? 'mb-3' : ''">
        <b-col
          :class="innerWidth < 991 ? '' : isFormulaType ? '' : 'margin-auto'"
          :cols="innerWidth < 991 ? 12 : isFormulaType? '6' : ''"
        >
          <customer-list-search
            :items="items"
            :full-canceled-participants-details="fullCanceledParticipantsDetails"
            @on:refresh-participants="onParticipantAddedFromList"
          />
        </b-col>
        <b-col
          :cols="innerWidth < 991 ? '6' : '3'"
          :align="innerWidth < 991 ? 'left' : 'center'"
          :class="innerWidth < 991 ? 'pr-1' : ''"
        >
          <div class="mt-1">
            <d-dropdown-form
              :key="'d-drop-down' + dropDownKey"
              :show="show"
              :hide="hide"
              :min-width="minWidth"
              identifier="custom-client"
            >
              <template v-slot:dropdownbutton>
              <span
                @on:button-click="show = !show"
                :class="innerWidth > 700 ? 'field-name-class pointer' : 'color-primary-custom d-btn-lg font-text-title btn button-custom-width'"
              >
                <i
                  v-if="innerWidth > 700"
                  class="font-size-larger fa fa-user-plus"
                ></i>
                <label :class="innerWidth > 700 ? 'pointer mt-1 ml-2 font-size-larger btn-label' : 'mb-0'">
                  {{ shortTextClient }}
                </label>
              </span>
              </template>
              <template v-slot:dropdownbody>
                <custom-form
                  :key="'custom-form' + dropDownKey"
                  :custom-client="customClient"
                  @on:form-submit="onCustomerAdded"
                />
              </template>
            </d-dropdown-form>
          </div>
        </b-col>
        <b-col
          v-if="!isFormulaType"
          :cols="innerWidth < 991 ? '6' : '3'"
          :align="innerWidth < 991 ? 'right' : 'center'"
          :class="innerWidth < 991 ? 'pl-1' : ''"
        >
          <div class="mt-1">
          <span
            :class="innerWidth > 700 ? 'field-name-class pointer' : 'color-primary-custom-light d-btn-sm font-text-title btn button-custom-width'"
            @click="addVisitor"
          >
            <i
              v-if="innerWidth > 700"
              class="font-size-larger fa fa-user-plus"
            >
            </i>
            <label :class="innerWidth > 700 ? 'ml-2 pointer font-size-larger btn-label mt-1' : 'mb-0'">
              {{ shortTextVisitor }}
            </label>
          </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row
      v-if="blockPrices.length > 0"
      class="mt-2"
    >
      <b-col
        cols="12"
        :class="participantsSelection ? 'field-name-class' : 'participants-label'"
      >
        {{ $t('components.custom.planning.booking-details-modal.general-information.visitors') }}
      </b-col>
      <b-col
        cols="12"
        :class="$store.getters['layout/getInnerWidth'] > 900 ? '' : 'p-0'"
      >
        <participants-table
          v-if="!isLoading"
          :items="items"
          :booking="booking"
          :discounts="discounts"
          :variations="variations"
          :booking-type="bookingType"
          :loaded-variations="loadedVariations"
          :canceled-participants="canceledParticipants"
          :full-canceled-participants-details="fullCanceledParticipantsDetails"
          @on:discount-icon:click="onDiscountIconClick"
          @on:undiscount-icon:click="onUnDiscountIconClick"
        />
      </b-col>
    </b-row>
    <template
      v-else
    >
      <div
        v-if="$store.getters['playgrounds/getLoader'] === false"
        class="mt-5 pt-5 error-zone error-label"
      >
        <label class="text-danger h6">
          {{
            $t('views.bookings.index.new.this-playground') + '« ' + selectedPlaygroundName + ' »' + $t('views.bookings.index.new.error-playground')
          }}
        </label>
      </div>
      <div
        v-else
        class="mt-5 pt-5 error-zone error-label"
      >
        <b-spinner
          variant="blue-light-spinner"
          type="grow"
        ></b-spinner>
      </div>
    </template>
  </div>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import Participant from "@/classes/doinsport/Participant";
import {randomString} from "@/utils/form";
import {FORMULA_TYPE} from "@/classes/doinsport/Price";

const MAX_DROPDOWN_WIDTH = 334;
const MIN_DROPDOWN_WIDTH = 250;

export default {
  components: {
    CustomForm: () => import('@form/client/CustomForm'),
    ParticipantsTable: () => import('@form/booking/create-or-update/participants/ParticipantsTable'),
    CustomerListSearch: () => import('@form/booking/create-or-update/participants/CustomerListSearch')
  },
  props: {
    booking: {
      type: Object,
      default: this,
    },
    loadedVariations: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    variations: {
      type: Array,
      default: () => [],
    },
    discounts: {
      type: Array,
      default: () => []
    },
    bookingType: {
      type: String,
      default: 'default'
    },
    canceledParticipants: {
      type: Array,
      default: () => [],
    },
    fullCanceledParticipantsDetails: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    dropDownKey: 0,
    show: false,
    hide: false,
    defaultDropDownSize: 250,
    size: 250,
    customClient: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
    },
  }),
  computed: {
    participantsSelection () {
      if (this.blockPrice && this.blockPrice.activityType === FORMULA_TYPE && this.items.length > 0) {
        return false
      }
      return true;
    },
    shortTextClient() {
      if (this.$store.getters["layout/getInnerWidth"] > 991) {
        return this.$t('views.client.index.new.default');
      } else {
        return this.$t('components.custom.planning.booking-details-modal.general-information.add-new-short');
      }
    },
    shortTextVisitor() {
      if (this.$store.getters["layout/getInnerWidth"] > 991) {
        return this.$t('components.custom.planning.booking-details-modal.general-information.add-visitor');
      } else {
        return this.$t('components.custom.planning.booking-details-modal.general-information.visitor-short');
      }
    },
    isLoading() {
      return this.$store.getters["playgrounds/getLoader"];
    },
    minWidth() {
      if (this.customClient.email === null && this.customClient.phoneNumber === '') {
        return MIN_DROPDOWN_WIDTH + 'px';
      } else {
        if (!((this.customClient.email && this.customClient.email.length >= 29) || (this.customClient.phoneNumber && this.customClient.phoneNumber.length >= 29))) {
          if (this.customClient.phoneNumber && this.customClient.phoneNumber.length > 13 && this.customClient.phoneNumber.length < 24) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.phoneNumber.length;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.phoneNumber && this.customClient.phoneNumber.length >= 24 && this.customClient.phoneNumber.length < 29) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.phoneNumber.length * 3;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.phoneNumber && this.customClient.phoneNumber.length >= 29) {
            return MAX_DROPDOWN_WIDTH + 'px';
          }
          if (this.customClient.email && this.customClient.email.length > 13 && this.customClient.email.length < 24) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.email.length + 2;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.email && this.customClient.email.length >= 24 && this.customClient.email.length < 29) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.email.length * 3;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.email && this.customClient.email.length >= 29) {
            return MAX_DROPDOWN_WIDTH + 'px';
          }
          return MIN_DROPDOWN_WIDTH + 'px';
        } else {
          return MAX_DROPDOWN_WIDTH + 'px';
        }
      }
    },
    selectedPlaygroundName() {
      const playground = this.$store.getters["playgrounds/getPlaygrounds"].find(el => el['@id'] === this.booking.playgrounds[0]);

      return isNotUndefinedAndNotNull(playground) ? playground.name : '';
    },
    innerWidth() {
      return this.$store.getters["layout/getInnerWidth"];
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    blockPrice() {
      if (isNotUndefinedAndNotNull(this.booking.timetableBlockPrice)) {
        return this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);
      }

      return null;
    },
    isFormulaType() {
      if (this.blockPrice) {
        return this.blockPrice.activityType === FORMULA_TYPE;
      }

      return false;
    }
  },
  methods: {
    onDiscountIconClick(item) {
      this.$emit('on:discount-icon:click', item);
    },
    onUnDiscountIconClick(item) {
      this.$emit('on:undiscount-icon:click', item);
    },
    onParticipantAddedFromList(participant) {
      const participantDetails = this.fullCanceledParticipantsDetails.find(el => el.id === participant.id);

      if ("undefined" !== typeof participantDetails) {

        const findCanceledParticipant = this.canceledParticipants.find(el => el === participantDetails.iriParticipant);

        if ("undefined" !== typeof findCanceledParticipant) {
          this.canceledParticipants.splice(this.canceledParticipants.indexOf(findCanceledParticipant), 1);
        }
      }
    },
    onCustomerAdded(customer) {
      this.dropDownKey++;
      this.resetCustomClient();
      this.addParticipantRecentlyCreated(customer);
    },
    addVisitor() {
      const categories = this.$store.getters["multipleBooking/getPriceCategories"];

      const participant = new Participant();
      participant.id = randomString();
      participant.about = this.$t('components.custom.planning.booking-details-modal.general-information.visitor');
      participant.participant = null;
      participant.bookingOwner = false;
      participant.actions = ['delete'];
      participant.phoneNumber = '-';
      participant.category = categories.length === 1 ? categories[0].category['@id'] : null;
      participant.partcipantPreview = {
        photo: null,
        values: {
          firstName: 'I', lastName: 'N'
        }
      }

      this.items.push(participant);
      this.$bus.$emit('on:refresh-discounts');
    },
    addParticipantRecentlyCreated(customer) {
      const categories = this.$store.getters["multipleBooking/getPriceCategories"];

      const participant = new Participant(customer);
      participant.phoneNumber = isNotUndefinedAndNotNull(customer.phoneNumber) ? customer.phoneNumber : '-';
      participant.about = customer.fullName;
      participant.bookingOwner = false;
      participant.category = categories.length === 1 ? categories[0].category['@id'] : null;
      participant.participant = {
        label: customer.fullName,
        code: customer['@id'],
        item: customer,
        iri: customer['@id']
      };
      participant.actions = ['delete'];
      participant.type = 'participant';
      participant.partcipantPreview = {
        photo: null,
        values: customer
      }
      this.items.push(participant);
      this.$bus.$emit('on:refresh-discounts');
    },
    resetCustomClient() {
      this.customClient = {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
      };
    }
  },
}
</script>
<style scoped lang="scss">
@import "@lazy/planning/_mobile-buttons-add-new.scss";

.font-size-larger {
  font-size: 20px;
  color: #435179;
}

.btn-label {
  font: normal normal bold 17px/27px Avenir;
  letter-spacing: 0.56px;
  color: #435179;
  opacity: 1;
}

.error-label {
  font: normal normal bold 17px/27px Avenir;
  letter-spacing: 0.56px;
  opacity: 1;
}

.error-zone {
  display: flex;
  justify-content: center;
}

.participants-label {
  font-size: 20px;
  font-family: 'Avenir';
  font-weight: 900;
  color: #4D4F5C;
}
</style>
