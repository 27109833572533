<template>
  <div>
    <b-row class="padding-container">
      <b-col
        :cols="innerWidth < 991 ? 12 : 5"
      >
        <booking-form
          :booking="booking"
          :slot-data="slotData"
          :booking-type="bookingType"
          :selected-playground="selectedPlayground"
          @on:timetable-block-price:changed="onBlockPriceUpdate"
        />
      </b-col>
      <b-col
        :cols="innerWidth < 991 ? 12 : 7"
      >
        <div
          :class="innerWidth < 991 ? '' : 'container-fluid'"
        >
          <participants-form
            :items="items"
            :booking="booking"
            :discounts="discounts"
            :variations="variations"
            :loadedVariations="loadedVariations"
            :booking-type="bookingType"
            :key="'participant-form'+ formKey"
            :canceled-participants="canceledParticipants"
            :full-canceled-participants-details="fullCanceledParticipantsDetails"
            @on:discount-icon:click="onDiscountIconClick"
            @on:undiscount-icon:click="onUnDiscountIconClick"
          />
        </div>
      </b-col>
    </b-row>
    <b-row
      class="padding-container"
      v-if="loaded"
    >
      <b-col
        :cols="innerWidth < 991 ? 12 : 5"
      >
        <booking-options
          :items="items"
          :booking="booking"
          :playground-options="playgroundOptions"
        />
      </b-col>
      <b-col
        :cols="innerWidth < 991 ? 12 : 7"
      >
        <div :class="innerWidth < 991 ? '' : 'container-fluid'">
          <booking-summary
            :items="items"
            :booking="booking"
            :discounts="discounts"
            :variations="variations"
            :playground-options="playgroundOptions"
            @on:discount-icon:click="onDiscountIconClick"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BookingSummary from './BookingSummary'
import BookingOptions from './BookingOptions'
import BookingForm from "@form/booking/create-or-update/BookingForm";
import PlaygroundOptions from "@/classes/doinsport/PlaygroundOptions";
import ParticipantsForm from "@form/booking/create-or-update/participants/ParticipantsForm";
import {getBlockPriceOptions} from '@api/doinsport/services/playground/timetable-block.api';
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getPriceCategories, getPriceVariations} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {FORMULA_TYPE, LESSON_TYPE} from "@/classes/doinsport/Price";
import {MULTIPLE_BOOKING_TYPE} from "@/classes/doinsport/Playground";

export default {
  data() {
    return {
      formKey: 0,
      loaded: false,
      variations: [],
      priceCategories: [],
      loadedVariations: false,
      playgroundOptions: [],
    }
  },
  props: {
    booking: {
      type: Object,
      default: this
    },
    discounts: {
      type: Array,
      default: () => []
    },
    bookingType: {
      type: String,
      default: 'default'
    },
    selectedPlaygroundOptions: {
      type: Array,
      default: () => []
    },
    selectedPlayground: {
      type: Object,
      default: this
    },
    items: {
      type: Array,
      default: () => []
    },
    canceledParticipants: {
      type: Array,
      default: () => []
    },
    fullCanceledParticipantsDetails: {
      type: Array,
      default: () => []
    },
    slotData: {
      type: Object,
      default: this
    }
  },
  components: {
    ParticipantsForm,
    BookingForm,
    BookingSummary,
    BookingOptions
  },
  computed: {
    innerWidth() {
      return this.$store.getters["layout/getInnerWidth"];
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    blockPrice() {
      return this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);
    },
    blockPriceCategories () {
      return this.$store.getters['multipleBooking/getPriceCategories'];
    }
  },
  methods: {
    onDiscountIconClick(item) {
      this.$emit('on:discount-icon:click', item);
    },
    onUnDiscountIconClick(item) {
      this.$emit('on:undiscount-icon:click', item);
    },
    getPlaygroundOptions(blockPrice) {
      this.loaded = false;

      getBlockPriceOptions(blockPrice.id)
        .then((response) => {
          this.playgroundOptions = []

          response.data['hydra:member'].forEach(option => {
            let formattedOption = new PlaygroundOptions(option, {serialize: true})

            if (option.minQuantity) {
              formattedOption.qty = option.minQuantity
            }

            let currentOption;

            if (isNotUndefinedAndNotNull(this.booking.playgroundOptionsTmp)) {
              if (this.booking.playgroundOptionsTmp.length > 0) {
                currentOption = this.booking.playgroundOptionsTmp.find(el => el.option === formattedOption['@id']);
              }
            }

            if (this.selectedPlaygroundOptions.length > 0) {
              currentOption = this.selectedPlaygroundOptions.find(el => el.option['@id'] === formattedOption['@id']);
            }

            if (isNotUndefinedAndNotNull(currentOption)) {
              formattedOption.selected = true;
              formattedOption.qty = currentOption.quantity;
              formattedOption.discount = currentOption.discount;
            }

            this.playgroundOptions.push(formattedOption);
          })
        })
        .finally(() => {
          this.loaded = true;
        })
      ;
    },
    loadVariations(blockPrice, update = null) {
      this.loadedVariations = false;
      getPriceVariations(blockPrice.id, 100)
        .then((response) => {
          this.variations = response.data['hydra:member'];
        })
        .finally(() => {
          if (this.selectedPlayground.bookingType === MULTIPLE_BOOKING_TYPE) {
            this.loadBlockPriceCategories(blockPrice, update);
          } else {
            this.loadedVariations = true;
          }
        })
      ;
    },
    updateParticipantCategories () {
      for (const item of this.items) {
        const findCategory = this.blockPriceCategories.find(el => el.category['@id'] === item.category);

        if ("undefined" === typeof findCategory) {
          if (this.blockPriceCategories.length === 1) {
            item.category = this.blockPriceCategories[0].category['@id'];
          } else {
            item.category = null;
          }
        }
      }
    },
    loadBlockPriceCategories(blockPrice, update = null) {
      getPriceCategories(blockPrice.id, 100)
        .then((response) => {
          this.$store.commit('multipleBooking/setPriceCategories', response.data['hydra:member']);
          if (isNotUndefinedAndNotNull(update)) {
            this.updateParticipantCategories();
          }
        })
        .finally(() => {
          this.loadedVariations = true;
        })
      ;
    },
    onBlockPriceUpdate () {
      const blockPrice =  this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);

      this.getPlaygroundOptions(blockPrice);

      this.loadVariations(blockPrice, 'on:timetable-block-price:update');
    }
  },
  watch: {
    blockPrice: function (update) {
      if ((update && this.selectedPlayground.bookingType === 'unique')) {
        this.getPlaygroundOptions(this.blockPrice);
        this.loadVariations(this.blockPrice);
      }

      if (update && (this.selectedPlayground.bookingType === MULTIPLE_BOOKING_TYPE && this.blockPrice.activityType === LESSON_TYPE)) {
        this.getPlaygroundOptions(this.blockPrice);
        this.loadVariations(this.blockPrice);
      }

      if (update && (this.selectedPlayground.bookingType === MULTIPLE_BOOKING_TYPE && this.blockPrice.activityType === FORMULA_TYPE)) {
        this.getPlaygroundOptions(this.blockPrice);
        this.loadVariations(this.blockPrice);
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('multipleBooking/setPriceCategories', []);
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/bookings/_booking-container.scss";
</style>
