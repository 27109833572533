<template>
  <div class="summary-container">
    <h2>{{ $t('recurring.resume') }} </h2>
    <table class="custom-table">
      <tr v-if="hideOnBookingLesson">
        <th>{{ $t('views.bookings.index.summary.service') }}</th>
        <th>{{ $t('views.bookings.index.summary.rate') }}</th>
        <th>{{ $t('views.bookings.index.summary.amount') }}</th>
      </tr>
      <tr
        v-if="blockPrice && blockPrice.activityType !== 'leisure' && blockPrice.activityType !== 'formula' && blockPriceDetails && blockPriceDetails.qty > 0">
        <td>{{ blockPriceDetails.name }}</td>
        <td>{{ blockPriceDetails.qty }} x {{ blockPriceDetails.unitPrice + $currency }}</td>
        <td>{{ blockPriceDetails.price + $currency }}</td>
      </tr>

      <tr v-if="blockPrice && blockPrice.activityType === 'formula'">
        <td>{{ blockPriceDetails.name }}</td>
        <td>{{ booking.fixedParticipantsCount }} x {{ blockPriceDetails.unitPrice + $currency }}</td>
        <td>{{ blockPriceDetails.price + $currency }}</td>
      </tr>
      <tr
        v-for="(el, i) in variationsUsed"
        v-if="hasCategoryAndVariationUsed(el)"
        :key="'subscription' + i"
        class="border-top"
      >
        <td>{{ subscriptionCardName(el) }}</td>
        <td>{{ subscriptionPack(el.item, el.occ) }}</td>
        <td>{{ subscriptionPackPrice(el.item, el.occ) }}</td>
      </tr>
      <tr
        v-for="(el, i) in usedPriceCategories"
        :key="'item' + i"
        class="border-top"
      >
        <td>{{ el.item.category.label }}</td>
        <td> {{ categoryOcc(el) }}</td>
        <td> {{ categoryTotalPrice(el) }}</td>
      </tr>
      <tr
        v-for="(row, i) in summaryRows"
        :key="i"
        class="border-top"
      >
        <td>{{ row.name }}</td>
        <td>{{ row.qty }} x {{ row.unitPrice + $currency }}</td>
        <td v-if="!isOption(row)">{{ row.price + $currency }}</td>
        <td v-else>
          <discount-icon
            :item="row"
            @on:discount-icon:click="onDiscountOptionIconClick(row)"
            @on:undiscount-icon:click="onUnDiscountOptionIconClick(row)"
          />
        </td>
      </tr>
      <tr>
        <th class="total-amount" colspan="2">{{ $t('views.bookings.index.summary.total-amount') }}</th>
        <th>
          <discount-icon
            :key="'booking-discount' + discountKey"
            :item="bookingItem"
            @on:discount-icon:click="onDiscountIconClick"
            @on:undiscount-icon:click="onUnDiscountClick"
          />
        </th>
      </tr>
    </table>
  </div>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import DiscountIcon from "@custom/booking/DiscountIcon";
import BookingModel, {
  FORMULA_BOOKING_TYPE,
  LESSON_BOOKING_TYPE,
  SPORT_BOOKING_TYPE
} from "@/classes/doinsport/BookingModel";
import {_delete} from "@api/doinsport/services/httpService";
import {toPrice} from "@/utils/currency";
import {toFloatFixed} from "@/utils/string";
import {LEISURE_TYPE} from "@/classes/doinsport/Price";
import {hydrate, randomString} from "@/utils/form";

export default {
  name: 'Summary',
  data: () => ({
    summaryRows: [],
    variationsUsed: [],
    usedPriceCategories: [],
    totalPrice: 0,
    blockPriceRow: null,
    discountKey: 0,
    bookingItem: new BookingModel()
  }),
  components: {DiscountIcon},
  props: {
    booking: {
      type: Object,
      default: this,
    },
    items: {
      type: Array,
      default: () => [],
    },
    discounts: {
      type: Array,
      default: () => []
    },
    variations: {
      type: Array,
      default: () => []
    },
    playgroundOptions: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    playgroundOptions: {
      deep: true,
      handler() {
        this.loadSummaryRows();
      }
    },
    variations: {
      deep: true,
      handler() {
        this.loadSummaryRows();
      }
    },
    items: {
      deep: true,
      handler() {
        this.loadSummaryRows();
      }
    }
  },
  computed: {
    blockPriceDetails() {
      return this.blockPriceRow;
    },
    hideOnBookingLesson() {
      if ("undefined" !== typeof this.blockPrice) {
        if (this.blockPrice.activityType === LESSON_BOOKING_TYPE) {
          return this.blockPriceRow.qty > 0 || this.variationsUsed.length > 0 || this.summaryRows.length > 0;
        } else {
          return true;
        }
      }
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    priceCategories() {
      return this.$store.getters["multipleBooking/getPriceCategories"];
    },
    blockPrice() {
      if (isNotUndefinedAndNotNull(this.booking.timetableBlockPrice)) {
        return this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);
      }

      return null;
    },
  },
  methods: {
    hasCategoryAndVariationUsed(el) {
      if (this.blockPrice.activityType === LEISURE_TYPE && null === el.item.category) {
        return false;
      }
      return true;
    },
    subscriptionCardName(el) {
      if (this.blockPrice.activityType === LEISURE_TYPE && this.priceCategories.length > 0) {
        if (isNotUndefinedAndNotNull(el.item.category)) {
          return this.getParticipantCategoryFromItem(el.item.category).category.label + ' - ' + el.item.subscriptionCardUsed.name
        }
      } else {
        return el.item.subscriptionCardUsed.name;
      }
    },
    getParticipantCategoryFromItem(item) {
      const participantCategory = this.priceCategories.find(el => el.category['@id'] === item);

      return "undefined" !== typeof participantCategory ? participantCategory : null;
    },
    categoryOcc(el) {
      return el.occ + ' X ' + toFloatFixed(el.item.pricePerParticipant) + this.$currency;
    },
    categoryTotalPrice(el) {
      return (el.occ * toFloatFixed(el.item.pricePerParticipant)).toFixed(2) + this.$currency;
    },
    loadBlockPriceRow() {
      let qty = 0;

      if (this.blockPrice) {

        if (this.blockPrice.activityType !== LESSON_BOOKING_TYPE && this.blockPrice.activityType !== FORMULA_BOOKING_TYPE) {
          qty = this.blockPrice.maxParticipantsCountLimit - this.variationsCount();
        } else if (this.blockPrice.activityType === FORMULA_BOOKING_TYPE) {
          qty = this.booking.fixedParticipantsCount;
        } else {
          qty = this.items.length - this.variationsCount();
        }

        this.blockPriceRow = {
          name: this.blockPrice.name,
          qty,
          rawPrice: this.blockPrice.pricePerParticipant * qty,
          price: this.priceToCurrency(this.blockPrice.pricePerParticipant * qty),
          unitPrice: this.priceToCurrency(this.blockPrice.pricePerParticipant)
        }
      } else {
        this.blockPriceRow = null;
      }

    },
    subscriptionPack(item, occ) {
      const variationUsed = this.variations.find(el => el.subscriptionPlan['@id'] === this.getSubscriptionPlanItem(item)['@id']);

      if (isNotUndefinedAndNotNull(variationUsed)) {
        return occ + ' X ' + toFloatFixed(variationUsed.pricePerParticipant) + this.$currency;
      }
    },
    subscriptionPackPrice(item, occ) {
      const variationUsed = this.variations.find(el => el.subscriptionPlan['@id'] === this.getSubscriptionPlanItem(item)['@id']);

      if (isNotUndefinedAndNotNull(variationUsed)) {
        return (occ * toFloatFixed(variationUsed.pricePerParticipant)).toFixed(2) + this.$currency;
      }
    },
    hasSubscriptionCard(item) {
      return isNotUndefinedAndNotNull(item.subscriptionCardUsed);
    },
    variationsCount() {
      let count = 0;

      for (const item of this.items) {
        if (this.hasSubscriptionCard(item)) {
          count++;
        }
      }

      return count;
    },
    loadTotalPrice() {
      let total = 0;

      this.summaryRows.forEach(row => {
        if ("undefined" !== typeof row.id) {
          total += row.rawPrice;
        }
      });

      let totalParticipantsPrice = 0;
      if (isNotUndefinedAndNotNull(this.blockPrice)) {
        let maxParticipantsCount = this.blockPrice.maxParticipantsCountLimit;

        if (this.blockPrice.activityType === SPORT_BOOKING_TYPE) {
          if (this.items.length > maxParticipantsCount) {
            if (this.variationsCount() === 0) {
              totalParticipantsPrice = totalParticipantsPrice + toFloatFixed(this.blockPrice.pricePerParticipant) * this.items.length;
            } else {
              totalParticipantsPrice = totalParticipantsPrice + toFloatFixed(this.blockPrice.pricePerParticipant) * (this.items.length - this.variationsCount());

              for (const item of this.items) {
                if (isNotUndefinedAndNotNull(item.subscriptionCardUsed)) {
                  totalParticipantsPrice = totalParticipantsPrice + parseFloat(toPrice(item.toPay));
                }
              }
            }
          } else {
            if (this.variationsCount() === 0) {
              totalParticipantsPrice = totalParticipantsPrice + toFloatFixed(this.blockPrice.pricePerParticipant) * maxParticipantsCount;
            } else {
              totalParticipantsPrice = totalParticipantsPrice + toFloatFixed(this.blockPrice.pricePerParticipant) * (maxParticipantsCount - this.variationsCount());

              for (const item of this.items) {
                if (isNotUndefinedAndNotNull(item.subscriptionCardUsed)) {
                  totalParticipantsPrice = totalParticipantsPrice + parseFloat(toPrice(item.toPay));
                }
              }
            }
          }
        } else if (this.blockPrice.activityType === FORMULA_BOOKING_TYPE) {
          totalParticipantsPrice = totalParticipantsPrice + toFloatFixed(this.blockPrice.pricePerParticipant) * parseInt(this.booking.fixedParticipantsCount);
        } else {
          for (const item of this.items) {
            totalParticipantsPrice = totalParticipantsPrice + parseFloat(toPrice(item.toPay));
          }
        }

        this.totalPrice = this.priceToCurrency(total);

        this.totalPrice = parseFloat(this.totalPrice) + parseFloat(totalParticipantsPrice);

        for (const discount of this.discounts) {
          if (discount.amountType === 'value') {
            this.totalPrice = this.totalPrice - discount.amount;
          } else {
            let initialPrice = 0;
            const initialPriceLabel = discount.initialPrice + '';

            if (isNotUndefinedAndNotNull(initialPriceLabel)) {
              if (initialPriceLabel.includes(this.$currency) || initialPriceLabel.includes('%')) {
                initialPrice = initialPriceLabel.split(this.$currency)[0];
              } else {
                initialPrice = initialPriceLabel;
              }
            }

            this.totalPrice = this.totalPrice - ((initialPrice * discount.amount) / 100);
          }
        }

        this.bookingItem.toPay = parseFloat(this.totalPrice).toFixed(2);
        this.loadVariationsUsed();
        this.loadUsedPriceCategories();
        this.loadBlockPriceRow();
      }
    },
    loadSummaryRows() {
      this.summaryRows = []

      this.playgroundOptions.forEach(option => {
        if (option.selected) {
          let findOption = this.discounts.find(el => el.bookingPlaygroundOption === option['@id'])

          if ("undefined" === typeof findOption) {
            findOption = this.discounts.find(el => el.id === option['@id'])
          }

          if (isNotUndefinedAndNotNull(findOption)) {
            findOption.initialPrice = (option.qty * option.price) / 100;
          }

          this.summaryRows.push({
            id: option['@id'],
            name: option.label,
            qty: option.qty,
            rawPrice: option.price * option.qty,
            price: this.priceToCurrency(option.price * option.qty),
            toPay: this.priceToCurrency(option.price * option.qty),
            unitPrice: this.priceToCurrency(option.price),
            rawUnitPrice: option.price,
            item: option,
            type: 'option'
          });
        } else {
          const findDiscount = this.discounts.find(el => el.bookingPlaygroundOption === option['@id']);

          if ("undefined" !== typeof findDiscount) {
            this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
          }
        }
      });
      this.updateDiscounts();
    },
    isOption(row) {
      return "undefined" !== typeof row.item;
    },
    onDiscountIconClick() {
      this.$emit('on:discount-icon:click', this.bookingItem);
    },
    onUnDiscountClick() {
      let findDiscount = null;

      if (isNotUndefinedAndNotNull(this.$route.params.id)) {
        findDiscount = this.discounts.find(el => el.id === this.bookingItem.discount.id);
        if (findDiscount && findDiscount.booking) {
          _delete(this.bookingItem.discount.id)
            .then((response) => {
              if (null !== findDiscount) {
                this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
              }
            })
            .finally(() => {
              this.loadSummaryRows();
            })
          ;
        } else {
          if (null !== findDiscount) {
            this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
          }

          this.loadSummaryRows();
        }
      } else {
        findDiscount = this.discounts.find(el => el.id === this.bookingItem.id);

        if (null !== findDiscount) {
          this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
        }

        this.loadSummaryRows();
      }
    },
    updateDiscounts() {
      for (const row of this.summaryRows) {
        let findDiscount = null;

        if (this.$route.params.id === null || "undefined" === typeof this.$route.params.id) {
          findDiscount = this.discounts.find(el => el.id === row.id);
        } else {
          findDiscount = this.discounts.find(el => el.id === row.id);

          if ("undefined" === typeof findDiscount) {
            findDiscount = this.discounts.find(el => el.bookingPlaygroundOption === row.id);
          }
        }

        if ("undefined" !== typeof findDiscount || findDiscount !== null) {
          row.discount = findDiscount;
        } else {
          row.discount = null;
        }
      }

      let bookingDiscount = null;

      bookingDiscount = this.discounts.find(el => el.id === this.bookingItem.id);

      if ("undefined" === typeof bookingDiscount) {
        bookingDiscount = this.discounts.find(el => el.booking === '/clubs/bookings/' + this.bookingItem.id);
      }

      if ("undefined" !== typeof bookingDiscount) {
        this.bookingItem.discount = bookingDiscount;
      } else {
        this.bookingItem.discount = null;
      }

      this.loadTotalPrice();

      this.discountKey++;
    },
    onDiscountOptionIconClick(option) {
      this.$emit('on:discount-icon:click', option);
    },
    deleteOptionsFromDiscounts(option) {
      const findDiscount = this.discounts.find(el => el.id === option.id);

      if ("undefined" !== typeof findDiscount) {
        this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
      }
    },
    onUnDiscountOptionIconClick(option) {
      if (this.$route.params.id === null || "undefined" === typeof this.$route.params.id) {
        this.deleteOptionsFromDiscounts(option);
        this.loadSummaryRows();
      } else {
        if (option.discount.bookingPlaygroundOption !== null) {
          _delete(option.discount.id)
            .then((response) => {
              const optionDiscount = this.discounts.find(el => el.bookingPlaygroundOption === option.discount.bookingPlaygroundOption);

              if ("undefined" !== typeof optionDiscount) {
                this.discounts.splice(this.discounts.indexOf(optionDiscount), 1);
              }
            })
            .finally(() => {
              this.loadSummaryRows();
            })
          ;
        } else {
          this.deleteOptionsFromDiscounts(option);
          this.loadSummaryRows();
        }
      }
    },
    priceToCurrency(price) {
      if (price > 0) {
        return (parseFloat(price) / 100).toFixed(2);
      }
      return `0,00`;
    },
    initBookingDiscount() {
      hydrate(this.bookingItem, this.booking);

      if (this.bookingItem.id === null) {
        this.bookingItem.id = randomString();
        this.bookingItem.discount = null;
      }

      this.bookingItem.type = 'booking';
    },
    loadBlockPriceVariations() {
      this.initBookingDiscount();
      this.loadSummaryRows();
    },
    getSubscriptionPlanItem(item) {
      let subscriptionPlanItem = null;

      if (isNotUndefinedAndNotNull(item.subscriptionCardUsed.subscriptionPlan)) {
        subscriptionPlanItem = item.subscriptionCardUsed.subscriptionPlan;
      } else {
        subscriptionPlanItem = item.subscriptionCardUsed.plan;
      }

      return subscriptionPlanItem;
    },
    loadVariationsUsed() {
      this.variationsUsed = [];

      for (const item of this.items) {
        if (this.hasSubscriptionCard(item)) {
          let occ = 0;

          for (const el of this.items) {
            if (this.hasSubscriptionCard(el)) {
              if (this.getSubscriptionPlanItem(item)['@id'] === this.getSubscriptionPlanItem(el)['@id'] && item.category === el.category) {
                occ++;
              }
            }
          }

          const findEl = this.variationsUsed.find((el) => el.item.category === item.category && this.getSubscriptionPlanItem(el.item)['@id'] === this.getSubscriptionPlanItem(item)['@id']);

          if ("undefined" === typeof findEl) {
            this.variationsUsed.push({item: item, occ: occ});
          } else {
            if (isNotUndefinedAndNotNull(item.category)) {
              const findElCat = this.variationsUsed.find(el => el.item.category === item.category);
              if ("undefined" === typeof findElCat) {
                this.variationsUsed.push({item: item, occ: occ});
              }
            }
          }
        }
      }
    },
    loadUsedPriceCategories() {
      this.usedPriceCategories = [];

      if (this.blockPrice.activityType === LEISURE_TYPE) {
        for (const item of this.items) {
          if (null !== this.getParticipantCategoryFromItem(item.category) && false === this.hasSubscriptionCard(item)) {
            let occ = 0;

            for (const el of this.items) {
              if (el.category === item.category && !this.hasSubscriptionCard(el)) {
                occ++;
              }
            }

            const findEl = this.usedPriceCategories.find(el => el.item.category['@id'] === item.category);

            if ("undefined" === typeof findEl) {
              this.usedPriceCategories.push({item: this.getParticipantCategoryFromItem(item.category), occ: occ});
            }
          }
        }
      }


    }
  },
  created() {
    this.loadBlockPriceVariations();
  },
  mounted() {
    this.$bus.$on('on:refresh-discounts', this.loadSummaryRows);
  }
  ,
  beforeDestroy() {
    try {
      this.$bus.$off('on:refresh-discounts', this.loadSummaryRows);
    } catch (e) {
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/table/_custom-table.scss";
@import "@lazy/bookings/_booking-summary.scss";
</style>
