<template>
  <div>
    <div
      v-if="playgroundOptions.length > 0"
      class="options-container"
    >
      <h2> {{ $t('booking-options') }} </h2>
      <div
        class="option d-flex align-items-center"
        v-for="option in playgroundOptions"
        :key="option['@id']"
      >
        <input
          :id="option['@id']"
          type="checkbox"
          class="checkbox-container-style"
          v-model="option.selected"
          @change="updateOptions"
        />
        <label :for="option['@id']">
          {{ option.label }} (+{{ priceToCurrency(option.price) }})
        </label>
        <b-form-select
          v-model="option.qty"
          v-if="option.quantifiable"
          :options="getQty(option)"
          @change="updateOptions"
        ></b-form-select>
      </div>
    </div>
  </div>
</template>
<script>
import {toFloatFixed} from "@/utils/string";

export default {
  name: 'Options',
  props: {
    booking: {
      type: Object,
      default: this,
    },
    playgroundOptions: {
      type: Array,
    },
  },
  methods: {
    priceToCurrency(price) {
      if (price > 0) {
        return `${toFloatFixed(price)}${this.$currency}`;
      }
      return `0,00${this.$currency}`;
    },
    getQty(option) {
      let array = [];

      for (let i = option.minQuantity; i <= option.maxQuantity; i++) {
        array.push(i);
      }

      return array;
    },
    updateOptions() {
      this.booking.playgroundOptionsTmp = [];

      this.playgroundOptions.forEach(option => {
        if (option.selected) {
          this.booking.playgroundOptionsTmp.push({
            option: option['@id'],
            quantity: option.qty
          });
        }
      });
    }
  },
  created() {
    this.updateOptions();
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/bookings/_booking-options.scss";
</style>
