<template>
  <b-row>
    <b-col
      cols="12"
      class="field-name-class mt-2"
    >
      {{ $t('components.custom.planning.booking-details-modal.general-information.nbPlayers') }}
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <select
        :key="'fixed-participant-count' + fixedParticipantCountKey"
        v-model.number="booking.fixedParticipantsCount"
        size="sm"
        class="form-control background-light-blue-inputs"
        @change="onFixedParticipantsCountChange"
      >
        <option disabled :value="null">
          {{ $t('general.actions.choose') }}
        </option>
        <template v-for="(nb, key) in nbParticipantsList">
          <option
            :key="'limit-' + key"
          >
            {{ nb }}
          </option>
        </template>
      </select>
    </b-col>
  </b-row>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    fixedParticipantCountKey: 0,
  }),
  props: {
    booking: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    blockPrice() {
      return this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);
    },
    nbParticipantsList () {
      let list = [];

      if (isNotUndefinedAndNotNull(this.blockPrice)) {
        let min = parseInt(this.blockPrice.minParticipantsCountLimit);
        const max = parseInt(this.blockPrice.maxParticipantsCountLimit);

        for (let i = min; i <= max; i++) {
          list.push(i);
        }
        return list;
      }
    }
  },
  methods: {
    onFixedParticipantsCountChange () {
      this.$bus.$emit('on:refresh-discounts');
    }
  },
  mounted () {
    if (isNotUndefinedAndNotNull(this.blockPrice) && this.booking.id === null) {
      this.booking.fixedParticipantsCount = this.blockPrice.minParticipantsCountLimit;
      this.$nextTick(() => {
        this.fixedParticipantCountKey ++;
        this.onFixedParticipantsCountChange();
      })
    }
  }
}
</script>
