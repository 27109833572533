<template>
  <div>
    <div class="d-flex flex-row align-items-end justify-content-end">
      <div
        class="font-weight-bold discount d-flex flex-row"
      >
        <div
          v-if="item.discount"
          class="discount-amount pointer font-weight-bold d-flex flex-row align-items-center justify-content-center ml-2"
          @click="$emit('on:undiscount-icon:click')"
        >
          <div>
            -{{ discountAmountCurrency(item.discount.amount) + amountTypeCurrency }}
          </div>
          <i
            class="btn-close-size icofont icofont-close"
          >
          </i>
        </div>
        <div
          class="font-weight-bold d-flex flex-row align-items-center justify-content-center ml-2"
          :class="item.discount ? '' : 'pointer'"
          @click="onDiscountClick"
        >
          {{ discountPriceCurrency(price) + $currency }}
          <img
            :src="discountIcon"
            class="ml-1"
            width="16px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {AMOUNT_TYPE} from "@/classes/doinsport/Discount";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  props: ['item'],
  computed: {
    isEditMode() {
      return this.$route.params.id !== null && "undefined" !== this.$route.params.id;
    },
    discountIcon() {
      return require('@/assets/icons/discount-tag.svg');
    },
    unDiscountIcon() {
      return require('@/assets/icons/discount-tag-red.svg');
    },
    price() {
      if (this.item.discount === null || "undefined" === typeof this.item.discount) {
        return this.item.toPay;
      } else {
        let toPayToInt = 0;

        if (this.item.toPay.includes(this.$currency)) {
          toPayToInt = this.item.toPay.split(this.$currency)[0];
        } else {
          toPayToInt = this.item.toPay;
        }

        if (this.item.discount.amountType === AMOUNT_TYPE) {
          if ("undefined" !== typeof this.item.discount.booking) {
            return toPayToInt;
          }
          return toPayToInt - this.item.discount.amount;
        } else {
          if ("undefined" !== typeof this.item.discount.booking) {
            return toPayToInt;
          }
          return toPayToInt - (toPayToInt * this.item.discount.amount) / 100;
        }

      }
    },
    amountTypeCurrency() {
      return this.item.discount.amountType === 'value' ? this.$currency : '%';
    }
  },
  methods: {
    onDiscountClick() {
      if (false === isNotUndefinedAndNotNull(this.item.discount)) {
        this.$emit('on:discount-icon:click');
      }
    },
    discountPriceCurrency(amount) {
      return parseFloat('' + amount).toFixed(2);
    },
    discountAmountCurrency(amount) {
      return parseFloat('' + amount).toFixed(2);
    }
  },
}
</script>

<style lang="scss" scoped>
.discount-amount {
  color: #EE3C31;
}

.discount {
  text-align: center;
}
</style>
