<template>
  <div>
    <b-row>
      <b-col size="6">
        <b-row>
          <b-col class="field-name-class">
            {{ $t('components.custom.planning.booking-details-modal.general-information.activity') }}
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <booking-activity
              :booking="booking"
              :validation="validation"
              :booking-type="bookingType"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="innerWidth"
        size="6"
      >
        <b-row>
          <b-col class="field-name-class">
            {{ $t('components.custom.planning.booking-details-modal.general-information.category') }}
          </b-col>
        </b-row>
        <b-row

          class="mt-2"
        >
          <b-col>
            <booking-category
              :booking="booking"
              :validation="validation"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="!isDefaultBooking && bookingType !== 'formula'">
      <b-row class="mt-2">
        <b-col class="field-name-class" cols="12">
          {{ $t('views.top-bar.users.create-or-update.coach') }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <coach-list-search
            :tags="booking.coaches"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="field-name-class" cols="12">
          {{ $t('views.dashboard.content.activities.events.course') }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <lessons
            :booking="booking"
            :slot-data="slotData"
            :selected-playground="selectedPlayground"
          />
        </b-col>
      </b-row>
      <nb-places-and-duration
        :booking="booking"
        :booking-type="bookingType"
      />
    </div>
    <div v-else-if="bookingType === 'default'">
      <b-row class="mt-2">
        <b-col
          class="field-name-class"
          cols="6"
        >
          {{ durationLabel }}
        </b-col>
        <b-col
          v-if="selectedPlayground.bookingType !== 'multiple'"
          cols="6"
          class="field-name-class"
        >
          {{ $t('components.custom.planning.booking-details-modal.general-information.nbPlayers') }}
        </b-col>
      </b-row>
      <timetable-block-price
        :booking="booking"
        :slot-data="slotData"
        :booking-type="bookingType"
        :selected-playground="selectedPlayground"
        @on:timetable-block-price:changed="$emit('on:timetable-block-price:changed')"
      />
    </div>
    <div v-else>
      <b-row>
        <b-col cols="6">
          <formula-block-prices
            :booking="booking"
            :selected-playground="selectedPlayground"
            :slot-data="slotData"
            @on:timetable-block-price:changed="$emit('on:timetable-block-price:changed')"
          />
        </b-col>
        <b-col cols="6">
          <nb-participants-limit
            v-if="$store.getters['playgrounds/getLoader'] === false"
            :booking="booking"
          />
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-2">
      <b-col
        v-if="innerWidth"
        class="field-name-class"
        cols="6"
      >
        {{ $t('components.custom.planning.booking-details-modal.general-information.playground') }}
      </b-col>
    </b-row>
    <b-row
      v-if="innerWidth"
      class="mt-2"
    >
      <b-col cols="12">
        <playgrounds
          :booking="booking"
          :booking-type="bookingType"
          :selected-playground="selectedPlayground"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="!isLessonType"
      :class="innerWidth ? 'mt-2' : 'mt-0'"
    >
      <b-col align="left">
        <b-row no-gutters>
          <b-col>
            <label class="col-form-label-sm field-name-class">
              {{ $t('components.custom.planning.booking-details-modal.general-information.note.title') }}
            </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea
              v-model="booking.comment"
              :placeholder="$t('components.custom.planning.booking-details-modal.general-information.note.place-holder')"
              :rows="innerWidth ? '5' : '2'"
              no-resize
              size="sm"
              class="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {DEFAULT_BOOKING_TYPE, LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import NbParticipantsLimit from "@form/booking/create-or-update/options/NbParticipantsLimit";

export default {
  data: () => ({
    validation: require('@validation/entities/doinsport/Booking.json')
  }),
  components: {
    NbParticipantsLimit,
    Lessons: () => import('@form/booking/create-or-update/options/Lessons'),
    Playgrounds: () => import('@form/booking/create-or-update/options/Playgrounds'),
    BookingActivity: () => import ('@form/booking/create-or-update/options/Activities'),
    BookingCategory: () => import ('@form/booking/create-or-update/options/Categories'),
    CoachListSearch: () => import('@form/booking/create-or-update/options/CoachsListSearch'),
    FormulaBlockPrices: () => import('@form/booking/create-or-update/options/FormulaBlockPrices'),
    NbPlacesAndDuration: () => import('@form/booking/create-or-update/options/NbPlacesAndDuration'),
    TimetableBlockPrice: () => import('@form/booking/create-or-update/options/timetable-block-price/Index')
  },
  props: {
    booking: {
      type: Object,
      default: this
    },
    selectedPlayground: {
      type: Object,
      default: this
    },
    slotData: {
      type: Object,
      default: this
    },
    bookingType: {
      type: String,
      default: DEFAULT_BOOKING_TYPE
    }
  },
  computed: {
    durationLabel() {
      if (this.selectedPlayground.bookingType === 'multiple') {
        return this.$t('components.custom.planning.booking-details-modal.general-information.block-price');
      } else {
        return this.$t('components.custom.planning.booking-details-modal.general-information.duration');
      }
    },
    isLessonType() {
      return this.bookingType === LESSON_BOOKING_TYPE;
    },
    isDefaultBooking() {
      return this.bookingType === DEFAULT_BOOKING_TYPE;
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    }
  }
}
</script>
